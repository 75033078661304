
import CallsCollection from "@/components/calls/CallsCollection.vue"

export default {
  name: "OrganizationCallHistory",
  components: { CallsCollection },
  props: {
    organization: {
      type: Object,
      default: null,
    },
  },
  setup() {},
}
